






















import { Component, Mixins, Vue, Prop } from 'vue-property-decorator'
import SidebarSwitcher from '@/components/organisms/v2/SidebarSwitcher.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import FillRound from '@/components/atoms/FillRound.vue'
import StyleSubjectBackground from '@/components/atoms/StyleSubjectBackground.vue'
import LoadEnableSubjectsApi from '@/mixins/utils/LoadEnableSubjectsApi'
import StudentBottomPanel from '@/components/organisms/StudentBottomPanel.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'

@Component({
  components: {
    SidebarSwitcher,
    ColoredBox,
    FillRound,
    StudentBottomPanel,
    ButtonBase,
  },
})
export default class Homework extends Mixins(StyleSubjectBackground, LoadEnableSubjectsApi) {
  public async mounted(): Promise<void> {
    Vue.prototype.$loading.start()
    this.loadEnableSubjects()
    Vue.prototype.$loading.complete()
  }

  private subjectDatas: { text: string; code: string }[] = []

  private userId = Vue.prototype.$cookies.get('authGdls').currentApiUserId
  private branchId = Vue.prototype.$cookies.get('dataGdls').branchId

  private get classCategoryCode() {
    return this.$route.params.classCategoryCode || ''
  }

  // カテゴリータイトル
  private get categoryTitle() {
    return '(補助教材 -【集団授業宿題用】英数デジタルテキスト)'
  }

  private async selectSubject(subjectCode: string): Promise<any> {
    // 教科コード保存
    await this.setSubjectCode(subjectCode)
    return this.$router.push(`/student/sozo/references/${subjectCode}`)
  }

  private async setSubjectCode(subjectCode: string) {
    const cookie = Vue.prototype.$cookies.get('dataGdls')
    cookie['subjectCode'] = subjectCode
    await Vue.prototype.$cookies.set('dataGdls', cookie, Function(`return (${process.env.VUE_APP_COOKIE_EXPIRE})`)())
  }

  private async loadEnableSubjects() {
    // プルダウン候補の取得
    const enableSubjects = await this.loadGdlsSubjectsApi(this.branchId, this.userId)

    this.subjectDatas = enableSubjects
      .filter((subject) => ['su', 'ei'].includes(subject.code))
      .map((subject) => {
        return { text: subject.name, code: subject.code }
    })
  }
}
